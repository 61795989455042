<template>
  <v-layout row pb-4 wrap class="mt-0 pt-0">
    <v-flex xs12 mb-3 mt-0>
      <log-out-message/>
      <user-details/>
    </v-flex>
  </v-layout>
</template>

<script>
import LogOutMessage from "@/components/LogOutMessage.vue";
import UserDetails from "@/components/UserDetails.vue";
export default {
  components: { LogOutMessage, UserDetails }
};
</script>

<template>
  <v-card class="mt-0 pt-0" max-width="450px" v-if="loggedOut">
    <v-card-title
      class="headline grey darken-4 font-weight-bold"
      primary-title
      py-0
      my-0
    >Please log in</v-card-title>
    <v-card-text class="text-xs-center text-sm-left grey--text">
      <div
        class="my-3"
      >You are currently not logged in. Please log in to see and update your account details.</div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  computed: {
    loggedOut: function() {
      return !this.$store.getters.credentials;
    },
    
  }
};
</script>

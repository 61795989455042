<template>
    <v-card class="mt-0 pt-0" max-width="450px" v-if="loggedOut">
    <v-card-title
      class="headline grey darken-4 font-weight-bold"
      primary-title
      py-0
      my-0
    >{{ userDBdata.firstName }} {{ userDBdata.lastName }} - {{ userDetails.displayName }} </v-card-title>
    <v-card-text class="text-xs-center text-sm-left grey--text">
      <div
        class='my-3 text-xs-left'
      >      
      <div>{{ userDBdata.street1 }}</div>
      <div>{{ userDBdata.street2 }}</div>
      <div>{{ userDBdata.city }}, {{ userDBdata.postcode }}</div>
      <div>{{ userDBdata.phoneNumber }}</div>
      <div>{{ userDBdata.birthDate }}</div>     
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  computed: {
    loggedOut: function() {
      return this.$store.getters.credentials;
    },
    userDBdata: function(){        
        return this.$store.getters.userDBdata;  
    },    
    userDetails: function(){        
        return this.$store.getters.userDetails;  
    },    
  },
};
</script>
